import React from "react"
import LayoutB1 from "../../components/layout-b1"
import './races.css'
import FastEntry from '../../components/fast-entry'
import { navigate } from "@reach/router"

export default class Races extends React.Component {
  // state={
  //   types:[{text:'不限',checked:true},{text:'路跑',checked:false},{text:'越野跑',checked:false}],
  //   locations:[{text:'不限',checked:true},{text:'北京',checked:false},{text:'上海',checked:false},{text:'苏州',checked:false},{text:'南京',checked:false}],
  //   years:[{text:'不限',checked:true},{text:'2017',checked:false},{text:'2018',checked:false},{text:'2019',checked:false},{text:'2020',checked:false}],
  //   states:[{text:'不限',checked:true},{text:'预约中',checked:false},{text:'报名中',checked:false},{text:'报名结束',checked:false}]
  // }
  componentWillMount() {
    this.setState({
      types:[{text:'不限',checked:true},{text:'路跑',checked:false},{text:'越野跑',checked:false}],
      locations:[{text:'不限',checked:true},{text:'北京',checked:false},{text:'上海',checked:false},{text:'苏州',checked:false},{text:'南京',checked:false}],
      years:[{text:'不限',checked:true},{text:'2017',checked:false},{text:'2018',checked:false},{text:'2019',checked:false},{text:'2020',checked:false}],
      states:[{text:'不限',checked:true},{text:'预约中',checked:false},{text:'报名中',checked:false},{text:'报名结束',checked:false}]
    })
  }
  filterType=(e)=>{
    this.state.types[e].checked=!this.state.types[e].checked
    this.setState({
      types:this.state.types
    })
  }
  filterLocation=(e)=>{
    this.state.locations[e].checked=!this.state.locations[e].checked
    this.setState({
      locations:this.state.locations
    })
  }

  filterYear=(e)=>{
    this.state.years[e].checked=!this.state.years[e].checked
    this.setState({
      years:this.state.years
    })
  }
  filterState=(e)=>{
    this.state.states[e].checked=!this.state.states[e].checked
    this.setState({
      states:this.state.states
    })
  }

  toDetail=(e)=>{
    navigate('/demos/themeB1/race_detail')
  }
  render() {
    const races=[{name:'宜新马拉松',poster:'//stor.ihuipao.com/image/6451b44ba98b26b28a434441eabf74bb.jpeg?imageView2/1/w/1200/h/430/',location:'中国.湖北.宜兴'},{name:'宜新马拉松',poster:'//stor.ihuipao.com/image/13b35f7c9c1ae256b50b9e1dfc975615.png?imageView2/2/w/1000/q/100',location:'中国.湖北.宜兴'}]
    const raceslist=races.map((race,index)=><div key={index} className="race" onClick={()=>this.toDetail(index)}>
      <div className="race-info">
        <div className="race-name">
          <div>{race.name}</div>
          <div className="countdown"><span>距离报名截止还有</span>12:28:10:10</div>
        </div>
        <div style={{backgroundImage:'url(' +race.poster + ')'}} className="race-poster"></div>
        <div style={{display:'flex',alignItems:'center'}} className="race-location">
          <img className="location-icon" src={require('../../images/b1/location.svg')} alt="地址"/>
          <div >{race.location}</div>
        </div>
      </div>
    </div>)

    const typeslist=this.state.types.map((type,index)=><div className="type" key={index} onClick={()=>this.filterType(index)} style={{background:type.checked?'black':'',color:type.checked?'white':'#4d4d4d'}}>
      {type.text}
    </div>)
    const locationslist=this.state.locations.map((location,index)=><div className="location" key={index} onClick={()=>this.filterLocation(index)} style={{background:location.checked?'black':'',color:location.checked?'white':'#4d4d4d'}}>
      {location.text}
    </div>)
    const yearslist=this.state.years.map((year,index)=><div className="year" key={index} onClick={()=>this.filterYear(index)} style={{background:year.checked?'black':'',color:year.checked?'white':'#4d4d4d'}}>
      {year.text}
    </div>)
    const stateslist=this.state.states.map((state,index)=><div className="state" key={index} onClick={()=>this.filterState(index)} style={{background:state.checked?'black':'',color:state.checked?'white':'#4d4d4d'}}>
      {state.text}
    </div>)
    return(
      <LayoutB1 location={this.props.location} >
        <div className="all-races">
          <FastEntry />
          <div className="all-races-right">
            <div className="filter-types">
              <div className="filter-container">
                <div className="filter-title">类型</div>
                <div className="filter-list">
                  {typeslist}
                </div>
              </div>
              <div className="filter-container">
                <div className="filter-title">地点</div>
                <div className="filter-list">
                  {locationslist}
                </div></div>
              <div className="filter-container">
                <div className="filter-title">时间</div>
                <div className="filter-list">
                  {yearslist}
                </div>
              </div>
              <div className="filter-container">
                <div className="filter-title">状态</div>
                <div className="filter-list">
                  {stateslist}
                </div>
              </div>
            </div>
            <div className="races-list">
              {raceslist}
            </div>
          </div>
        </div>
      </LayoutB1>
    )
  }
}

